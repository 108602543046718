@import 'bootstrap';
html {
  padding-top: 60px;
  height: 100%;
}
/* General Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f5f9f7;
  color: charcoal;
}
.hm-nav {
  background-color: #559d78;
  width: 100%;
}
.hm-nav .nav-link {
  color: #ffffff;
}
.hm-nav .navbar-brand {
  color: #ffffff;
}

.hm-nav .nav-link:hover {
  color: black;
}
.navbar-toggler {
  color: #ffffff;
}

.position-absolute {
  cursor: pointer;
}

/* Time Column */
.time-column {
  background-color: #f5f9f7;
  color: charcoal;
}

/* Sticky Room Headers */
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #559d78;
  color: white;
  border-color: #b0d4c1;
}

/* Events */
.event-default {
  background-color: #b0d4c1;
  color: charcoal;
}

.event-18plus {
  background-color: #b02d2b;
  color: white;
}
/* Paid Event */
.event-paid {
  background-color: #f5c6cb; /* Soft red/pink */
  color: #842029; /* Darker red text */
  border: 1px solid #f5c6cb; /* Border matching background */
}

/* Modal */
.modal-header {
  background-color: #559d78;
  color: white;
}

.modal-body {
  background-color: #f5f9f7;
}

.modal-footer {
  background-color: #b0d4c1;
}

.word-wrap {
  white-space: normal; /* Allows text to wrap to the next line */
  word-wrap: break-word; /* Ensures long words break within the block */
  overflow-wrap: break-word; /* Handles text overflow */
  overflow: hidden; /* Prevents text overflow from spilling */
}
/* Tabs Container */
.tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* Default Tab Style (Inactive State) */
.tab {
  padding: 10px 20px;
  margin: 0 5px;
  border: 1px solid #b0d4c1;
  border-radius: 5px;
  background-color: #f5f9f7; /* Light background */
  color: charcoal; /* Dark text */
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Active Tab */
.tab.active-tab {
  background-color: #559d78; /* Primary green */
  color: white; /* White text for active tab */
  border-color: #559d78; /* Green border */
}

/* Hover State */
.tab:hover {
  background-color: #468d6a; /* Slightly darker green for hover */
  color: white; /* White text for hover */
}

/* Focus State */
.tab:focus {
  outline: 3px solid #b0d4c1; /* Focus outline for accessibility */
  background-color: #559d78; /* Match active background */
  color: white;
}

/* Reset Inactive Tabs After Click */
.tab:not(.active-tab) {
  background-color: #f5f9f7; /* Reset background for inactive tabs */
  color: charcoal; /* Reset text color for inactive tabs */
}